import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY, USER_INFO_KEY } from './../helpers/constant';

export const setUserData = (value) => {
    localStorage.setItem(ACCESS_TOKEN_KEY, value.access_token);
    localStorage.setItem(REFRESH_TOKEN_KEY, value.refresh_token);
    localStorage.setItem(USER_INFO_KEY, JSON.stringify(value.user_info));
};

export const setUserInfo = (data) => {
    localStorage.setItem(USER_INFO_KEY, JSON.stringify(data));
};
export const getUserData = () => {
    let user_info = localStorage.getItem(USER_INFO_KEY) || null;
    let access_token = localStorage.getItem(ACCESS_TOKEN_KEY) || null;
    let refresh_token = localStorage.getItem(REFRESH_TOKEN_KEY) || null;
    return { user_info: user_info ? JSON.parse(user_info) : null, access_token, refresh_token };
};
export const setAccessToken = (access_token) => {
    localStorage.setItem(ACCESS_TOKEN_KEY, access_token);
};
export const setRefreshToken = (refresh_token) => {
    localStorage.setItem(REFRESH_TOKEN_KEY, refresh_token);
};
export const removeUserData = () => {
    localStorage.removeItem(ACCESS_TOKEN_KEY);
    localStorage.removeItem(REFRESH_TOKEN_KEY);
    localStorage.removeItem(USER_INFO_KEY);
};
export const getAccessToken = () => {
    return localStorage.getItem(ACCESS_TOKEN_KEY) || null;
};
export const getRefreshToken = () => {
    return localStorage.getItem(REFRESH_TOKEN_KEY) || null;
};
export const getUserInfo = () => {
    let user_info = localStorage.getItem(USER_INFO_KEY) || null;
    if (user_info) {
        return JSON.parse(user_info);
    }
    return null;
};
export const isAuthenticate = () => {
    let user_info = localStorage.getItem(USER_INFO_KEY) || null;
    let access_token = localStorage.getItem(ACCESS_TOKEN_KEY) || null;
    let refresh_token = localStorage.getItem(REFRESH_TOKEN_KEY) || null;
    return user_info && access_token && refresh_token ? true : false;
};
export const clearAll = () => {
    localStorage.clear();
};
export const useStorage = () => {
    return {
        isAuthenticate,
        clearAll,
        getUserInfo,
        getRefreshToken,
        setUserData,
        getUserData,
        setAccessToken,
        setRefreshToken,
        removeUserData,
        getAccessToken,
        setUserInfo
    };
};